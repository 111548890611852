import { Link } from 'designsystem/components/1-atoms/Links';
import { LinkLists, LinkListsProps } from 'designsystem/components/3-organisms/LinkLists';
import { LinkFeature } from 'features/Modules/Link';

export const FooterLinksFeature: React.FC<Content.FooterLinks> = ({ content }) => {
	const { lists } = content?.properties ?? {};
	const linkLists: LinkListsProps['lists'] = lists.map((linkLists) => {
		return linkLists.content.properties.linkList.map((list) => {
			return {
				heading: list.content.properties.heading,
				links: list.content.properties.links.map((linkData, index) => {
					const { link, language } = linkData?.content?.properties ?? {};
					if (!language) {
						return <LinkFeature style="arrow" key={index} {...link} />;
					} else {
						// This is necessary as long as we have third party scripts that
						// can only detect language updates through browser reloads
						return (
							<Link url={link?.url} target={link?.target} key={index}>
								{link?.name}
							</Link>
						);
					}
				}),
			};
		});
	});

	return linkLists && <LinkLists lists={linkLists} />;
};
