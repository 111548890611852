import { LinkList, LinkListProps } from 'components/2-molecules/LinkList';
import styles from './LinkLists.module.scss';

export interface LinkListsProps {
	lists: LinkListProps[][];
}

export const LinkLists: React.FC<LinkListsProps> = ({ lists }) => {
	return (
		<div className={styles.LinkLists}>
			{lists &&
				lists.map((list, index) => {
					return (
						<div key={index}>
							{Array.isArray(list) &&
								list.map((linkList, index) => {
									return (
										<div key={index} className={styles.LinkLists_wrapper}>
											<LinkList className={styles.LinkLists_list} heading={linkList.heading} links={linkList.links} />
										</div>
									);
								})}
						</div>
					);
				})}
		</div>
	);
};
