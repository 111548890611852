import classNames from 'classnames';
import { createElement } from 'react';
import styles from './LinkList.module.scss';

export interface LinkListProps {
	className?: string;
	color?: 'black';
	heading?: string;
	showHeading?: boolean;
	disableLinkStyling?: boolean;
	links?: React.ReactElement[];
}

export const LinkList: React.VFC<LinkListProps> = ({ className, color, heading, showHeading = true, disableLinkStyling = false, links }) => {
	return (
		<div className={classNames(styles.LinkList, styles[`LinkList___${color}`], className)}>
			{showHeading && (
				<p aria-hidden={true} className={styles.LinkList_heading}>
					{heading}
				</p>
			)}
			{links && (
				<ul className={styles.LinkList_list} aria-label={heading}>
					{links.map((link, index) => {
						return (
							<li key={index} className={styles.LinkList_listItem}>
								{createElement(link.type, { ...link.props, className: classNames(!disableLinkStyling && styles.LinkList_link) })}
							</li>
						);
					})}
				</ul>
			)}
		</div>
	);
};
